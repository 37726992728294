// 
// authentication.scss
//

.auth-bg-basic{
    background-image: url("../images/bg-1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .bg-overlay{
        opacity: 0.85;
    }
    @media (min-width: 768px){
        height: 100vh;
       
    }
}

// auth-pass-inputgroup
.auth-pass-inputgroup{
    input[type="text"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

.auth-bg{
    background-image: url("../images/bg-3.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
    .bg-overlay{
        opacity: 0.85;
    }
    @media (min-width: 768px){
        height: 100vh;
       
    }
    @media (max-width: 768px){
        clip-path: none; 
    }
}

// authentication home icon
.home-btn {
    position: fixed;
    top: 15px;
    right: 25px;
}

.authentication-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../images/auth-bg.jpg");
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-logo{
        .logo{
            margin: 0px auto;
        }

        .auth-logo-light{
            display: none;
            margin: 0px auto;
        }

        .auth-logo-dark{
            margin: 0px auto;
        }
    }

    .bg-overlay{
        opacity: 0.8;
    }

    .auth-cover-card{
        .authentication-bg{
            align-items: flex-start;
            justify-content: flex-start;
        }
        .bg-overlay{
            opacity: 0.6;
        }

        .form-floating{
            label{
                color: rgba($white, 0.5);
            }
            .form-control{
                background-color: rgba($white, 0.1);
                border-color: rgba($white, 0.1);
                color: $white;
            }

            .form-floating-icon{
                color: rgba($white, 0.5);
            }
        }
    }
}
.form-floating-custom{
    position: relative;
    >label{
        left: 48px;
    }
    >.form-control, >.form-select{
        padding-left: 60px;
    }
    .form-floating-icon{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        width: 56px;
        color: $text-muted;
    }
}

// error page
.error-font {
    font-size: 9rem;
    text-shadow: 4px 4px 6px rgba($gray-900, 0.26);
}

.mover-animation {
    animation: mover 1s infinite alternate;
}

@keyframes mover {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}


// Auth Pages
.auth-img{
  background-image: url("https://images.unsplash.com/photo-1593062096033-9a26b09da705?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  @media (max-width: 991.98px) {
      height: 250px;
  }
}

body[data-bs-theme="dark"] {
    .authentication-bg{
        background-image: url("../images/auth-bg-dark.jpg");

        .bg-overlay{
            opacity: 0.1;
        }

        .auth-logo{
    
            .auth-logo-dark{
                display: none;
            }
            .auth-logo-light{
                display: block;
            }
        }
    }
}