
//
// Datepicker
//
// flatpickr


.flatpickr-input {
  &[readonly] {
      background-color: $input-bg;
  }
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: $primary;
}

span.flatpickr-weekday {
  color: $white;
  font-weight: $font-weight-medium;
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
      &:hover {
          background-color: transparent !important;
      }
  }
}

.flatpickr-am-pm,
.numInput,
.numInputWrapper {
  &:focus,
  &:hover {
      background-color: transparent !important;
  }
}

.flatpickr-weekdays {
  height: 36px !important;
  border-bottom: 1px solid var(--#{$prefix}border-color);
}

.flatpickr-day {
  color: rgba(var(--#{$prefix}dark), 0.3) !important;
  &:hover,
  &:focus {
      background-color: rgba($light, 0.7) !important;
      color: rgba(var(--#{$prefix}dark), 0.3) !important;
  }
  &.today {
      border-color: $primary !important;
      background-color: rgba($primary, 0.1);
      &:hover,
      &:focus {
          color: rgba(var(--#{$prefix}dark), 0.3) !important;
          background-color: rgba($primary, 0.15) !important;
      }
  }
  &.selected {
      background-color: $primary !important;
      border-color: $primary !important;
      color: $white !important;

      &:hover{
        color: var(--#{$prefix}dark) !important;
      }
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
      color: var(--#{$prefix}tertiary-color) !important;
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
      background-color: var(--#{$prefix}light) !important;
      border-color: var(--#{$prefix}light) !important;
  }
  &.inRange {
      box-shadow: -5px 0 0var(--#{$prefix}light), 5px 0 0var(--#{$prefix}light);
  }
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-month {
      color: rgba($white, 0.9) !important;
      fill: rgba($white, 0.9) !important;
  }
}

.flatpickr-monthDropdown-month {
  color: rgba($black, 0.8);
}
.flatpickr-current-month {
  input.cur-year {
      &[disabled],
      &[disabled]:hover {
          color: rgba($white, 0.9) !important;
      }
  }
}

.flatpickr-time {
  input,
  .flatpickr-time-separator,
  .flatpickr-am-pm {
      color: var(--#{$prefix}dark) !important;
  }
}

.flatpickr-calendar {
  background-color: $dropdown-bg !important;
  box-shadow: 1px 0 0 var(--#{$prefix}border-color), -1px 0 0 var(--#{$prefix}border-color), 0 1px 0 var(--#{$prefix}border-color), 0 -1px 0 var(--#{$prefix}border-color),
      0 3px 13px rgba(0, 0, 0, 0.08) !important;
  &.hasTime {
      .flatpickr-time {
          border-top: 1px solid var(--#{$prefix}border-color) !important;
      }
  }
}

.flatpickr-months {
  border-radius: 5px 5px 0px 0px;
  .flatpickr-prev-month,
  .flatpickr-next-month {
      &:hover {
          svg {
              fill: rgba($white, 0.9) !important;
          }
      }
  }
}
