// 
// Extras pages.scss
//


// Gallery

.categories-filter {
    .list-inline-item    {
        position: relative;
        margin-right: 0;
        a {
            display: block;
            color: var(--#{$prefix}secondary-color);
            background-color: rgba($primary, 0.2);
            padding: 8px 15px;
            margin: 7px;
            cursor: pointer;
            border-radius: 4px;
            &.active{
                color: $white;
                background-color: $primary;
            }
        }

        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}


.gallery-box{
    position: relative;
    text-align: center;
    overflow: hidden;
    border: 1px solid var(--#{$prefix}border-color);

    .gallery-container{
        position: relative;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;

        a{
            display: block;
        }

        .gallery-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            opacity: 0;
            background: rgba($primary, 0.2);
            visibility: hidden;
            overflow: hidden;
            transition: all 0.4s ease-in-out 0s;

            .overlay-caption{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
            }
        }
    }
    .gallery-img {
        transition: all 0.2s ease-in-out;
    }
    .box-content{
        position: absolute;
        left: 0;
        right: 0;
        bottom: -28px;
        background-color: $card-bg;
        transition: all 0.2s;

        .title{
            font-size: 14px;
            margin-bottom: 4px;
        }
        .post{
            margin: 0;
            opacity: 0;
            transition: all 0.2s;
            color: $text-muted;
        }
    }

    &:hover {

        .box-content{
            bottom: 0;

            .post {
                opacity: 1;
            }
        }
        .gallery-img {
            transform: translateY(-28px);
        }
        .gallery-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
}

// Profile


.user-profile-img{
    position: relative;
    
    .overlay-content{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba($primary, 0.8);
        display: flex;
        height: 100%;
        color: rgba($white, 0.6);
        flex-direction: column;
    }
    .profile-img{
        width: 100%;
        height: 250px;
        object-fit: cover;
  
        @media (max-width:991px) {
            height: 160px;
        }
    }
  }


// Error Page


.error-title{
    font-size: 130px;
    @media (max-width: 425px) {
        font-size: 100px;
    }
    .blink-infinite {
        animation: infinite-blink 2s infinite;
      }
}
  
  @keyframes infinite-blink {
    60%,
    80% {
      color: $primary;
    }
    70%,
    100% {
      color: darken($dark, 15%);
    }
  }

.error-subtitle{
    font-weight: 400;
    background-color: #f5f5f8;
    max-width: 600px;
    font-size: 28px;
    letter-spacing: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    white-space: nowrap;
    @media (max-width: 425px) {
        font-size: 20px;
    }
}

// FAQs

.faq-icon {
    i {
        font-size: 80px;
        position: absolute;
        margin-top: -30px;
        right: -20px;
        opacity: .2;
    }
}

// pricing

.pricing-box{
    border-top: 2px solid $primary;

    .pricing-features{
        color: $text-muted;
        li{
            padding: 4px 0;
        }
    }
}

// error

.error-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 130px;
    @media (max-width: 1199.98px){
        font-size: 72px;

        .avatar-lg{
            width: 48px;
        }
    }
}

