//
// alerts.scss
//

.alert-top-border {
    background-color: $card-bg;
    border-color: var(--#{$prefix}border-color);
    border-top: 2px solid $light;
    color: var(--#{$prefix}body-color);
}


.alert-dismissible {
    .btn-close {
        background: transparent !important;
        &::after {
            background: transparent !important;
            content: "\F0156" !important;
            font-size: 18px;
            line-height:15px;
            font-family: "Material Design Icons" !important;
        }
    }
}

.alert-outline.alert-dark{
    color: var(--#{$prefix}dark); 
}

@each $color,
$value in $theme-colors {
    .alert-top-border{
        &.alert-#{$color} {
            border-top-color: $value;
            color: $value;
        }
    }

    .alert-outline{
        &.alert-#{$color} {
            border: 1px solid rgba($value, .8);
            color: $value;
        }
    }
}

