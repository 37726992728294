/* ==============
  Email
===================*/

.email-leftbar {
  width: 306px;
  float: left;
}

.email-rightbar {
  margin-left: 324px;
}

.chat-user-box {
  p.user-title {
    color: var(--#{$prefix}secondary-color);
    font-weight: 500;
  }
  p {
    font-size: 14px;
  }
}

.unread  {
  background-color: var(--#{$prefix}tertiary-bg);
  font-weight: 500;
  color: var(--#{$prefix}secondary-color) 
}

.message-list{
 &:hover{
  background-color: var(--#{$prefix}tertiary-bg);
 }
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}


.mail-list {
  a {
    display: block;
    color: var(--#{$prefix}secondary-color);
    line-height: 24px;
    padding: 8px 18px;

    &.active {
      color: $primary;
      font-weight: 500;
      h5{
        color: $primary;
      }
    }
  }
}



.message-list {
  display: block;
  padding-left: 0;

  .list {
    position: relative;
    display: block;
    height: 50px;
    cursor: default;
    transition-duration: .3s;
    a{
      color: var(--#{$prefix}secondary-color);
    }
    &.selected {
      background-color: rgba($gray-300, 0.6);
    }

    .col-mail {
      float: left;
      position: relative;
    }

    .col-mail-1 {
      width: 320px;

      .star-toggle,
      .checkbox-wrapper-mail,
      .dot {
        display: block;
        float: left;
      }

      .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0;
      }

      .checkbox-wrapper-mail {
        margin: 15px 10px 0 20px;
      }

      .star-toggle {
        margin-top: 18px;
        margin-left: 5px;
      }

      .title {
        position: absolute;
        top: 0;
        left: 110px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
        margin-top: 8px;
      }
    }

    .col-mail-2 {
      position: absolute;
      top: 14px;
      left: 320px;
      right: 0;
      bottom: 0;

      .subject,
      .date {
        position: absolute;
        top: 0;
      }

      .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
      }

      .date {
        right: 10px;
        width: 170px;
        padding-left: 80px;
      }
    }

    &.active,
    &.active:hover {
      box-shadow: inset 3px 0 0 $primary;
    }

  }


  .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    box-shadow: inset 0 0 0 1px var(--#{$prefix}gray-400);
    border-radius: 1px;

    input {
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ label {
      opacity: 1;
    }

    label {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      cursor: pointer;
      opacity: 0;
      margin-bottom: 0;
      transition-duration: .05s;
      top: 17px;
      &:before {
        content: "\F012C";
        font-family: "Material Design Icons";
        top: 0;
        height: 20px;
        color: var(--#{$prefix}secondary-color);
        width: 20px;
        position: absolute;
        margin-top: -16px;
        left: 4px;
        font-size: 13px;
      }
    }
  }
}

// EMAIL SEARCH
.email-search{
  .form-control{
    border: 0px;
    padding: .60rem .75rem;
  }
  span {
    position: absolute;
    z-index: 10;
    line-height: 38px;
    right: 13px;
    top: 2px;
    color: var(--#{$prefix}secondary-color);
}
}


@media (max-width: 575.98px) { 
  .message-list .list .col-mail-1 {
      width: 200px;
  }
}

